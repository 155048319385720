import FooterOne from "../../common/footer/FooterOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import SEO from "../../common/SEO";
import AboutSection from "./components/AboutSection";
import BenefitsSection from "./components/BenefitsSection";
import BillingSection from "./components/BillingSection";
import FacSection from "./components/FacSection";
import HeroSection from "./components/HeroSection";
import MeetingsSection from "./components/MeetingsSection";
import SocialSection from "./components/SocialSection";
import './styles/styles.scss';

const ComunicadorExpressivo = () => {
    return(
        <>
            <SEO title={"Comunicador Expressivo"}/>
            <HeaderTwo styles=" header-style-2" />
            <HeroSection />
            <AboutSection />
            <MeetingsSection />
            <BenefitsSection />
            <SocialSection />
            <BillingSection />
            <FacSection />
            <FooterOne />
        </> 
    )
}
export default ComunicadorExpressivo;